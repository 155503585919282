import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PreLoginApi from "./api/PreLoginApi";
import Spinner from "./util/Spinner/Spinner";
import dtm from "./util/rally-analytics/rally-analytics";
import styles from "./css/UcardActivation.module.css";
import { Grid } from "@abyss/web/ui/Grid";
import { Button } from "@abyss/web/ui/Button";
import UhcLogo from "../src/assets/images/uhc-logo-blue.svg";
import ArrowIcon from "../src/assets/images/arrow-icon.svg";
import PlusIcon from "../src/assets/images/plus-icon.svg";
import HandHoldingCardImage from "../src/assets/images/hand-holding-card-right.svg";
import HandHoldingCardHalfImage from "../src/assets/images/hand-holding-card-right-half.svg";
import HandHoldingCardTabletImage from "../src/assets/images/hand-holding-card-right-tablet.svg";
import CustomSegmentation from "mnr-segmentation-rule/customsegmentation";

export const UcardActivation = ({ isRegistrationRequest = false }, props) => {
  const [title, setTitle] = useState("");
  const [metaInfo, setMetaInfo] = useState("");
  const [disableButtons, setDisableButtons] = useState(false);
  const [signInLink, setSignInLink] = useState("");
  const [createAccountLink, setCreateAccountLink] = useState("");
  const [preLoginUrl, setPreLoginUrl] = useState("");
  const [ucardUrl, setUcardUrl] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [signInHeading, setSignInHeading] = useState("");
  const [signInDescription, setSignInDescription] = useState("");
  const [signInButtonText, setSignInButtonText] = useState("");
  const [createAccountHeading, setCreateAccountHeading] = useState("");
  const [createAccountDescription, setCreateAccountDescription] = useState("");
  const [createAccountButtonText, setCreateAccountButtonText] = useState("");

  let mapContent = new Map();

  const getSegmentContent = async () => {
    mapContent = await CustomSegmentation(
      "/prelogin-new/prelogin-new-page.infinity.json"
    );

    setTitle(mapContent.get("title"));
    setMetaInfo(mapContent.get("metaInfo"));
    setDisableButtons(mapContent.get("disable-buttons"));
    setUcardUrl(mapContent.get("ucard-url"));
    setHeading(mapContent.get("heading"));
    setDescription(mapContent.get("description"));
    setSignInHeading(mapContent.get("signin-heading"));
    setSignInDescription(mapContent.get("signin-description"));
    setSignInButtonText(mapContent.get("signin-button-text"));
    setCreateAccountHeading(mapContent.get("create-account-heading"));
    setCreateAccountDescription(mapContent.get("create-account-description"));
    setCreateAccountButtonText(mapContent.get("create-account-button-text"));

    console.log("mapContent==-" + mapContent);

    if (Boolean(mapContent.get("disable-plan-selector-flow"))) {
      if (
        window.location.host.indexOf("stage") > -1 ||
        window.location.host.indexOf("member.int") > -1 ||
        window.location.host.indexOf("medicare.int") > -1 ||
        window.location.host.indexOf("mymedicareaccount.int") > -1
      ) {
        setSignInLink(mapContent.get("stage-online-signin-link"));
        setCreateAccountLink(
          mapContent.get("stage-online-create-account-link")
        );
      } else if (
        window.location.host.indexOf("team") > -1 ||
        window.location.host.indexOf("localhost") > -1
      ) {
        setSignInLink(mapContent.get("dev-signin-link"));
        setCreateAccountLink(mapContent.get("dev-create-account-link"));
      } else if (
        window.location.host.indexOf("uat") > -1 ||
        window.location.host.indexOf("offline") > -1 ||
        window.location.host.indexOf("bluesteel") > -1
      ) {
        setSignInLink(mapContent.get("prod-offline-signin-link"));
        setCreateAccountLink(
          mapContent.get("prod-offline-create-account-link")
        );
      } else {
        setSignInLink(mapContent.get("prod-online-signin-link"));
        setCreateAccountLink(mapContent.get("prod-online-create-account-link"));
      }
    } else {
      if (
        window.location.host.indexOf("stage") > -1 ||
        window.location.host.indexOf("member.int") > -1 ||
        window.location.host.indexOf("medicare.int") > -1 ||
        window.location.host.indexOf("mymedicareaccount.int") > -1
      ) {
        setSignInLink(mapContent.get("stage-online-signin-link-plan-selector"));
        setCreateAccountLink(
          mapContent.get("stage-online-create-account-link-plan-selector")
        );
        setPreLoginUrl(mapContent.get("stage-online-prelogin-plan-selector"));
      } else if (
        window.location.host.indexOf("team") > -1 ||
        window.location.host.indexOf("localhost") > -1
      ) {
        setSignInLink(mapContent.get("dev-signin-link-plan-selector"));
        setCreateAccountLink(
          mapContent.get("dev-create-account-link-plan-selector")
        );
        setPreLoginUrl(mapContent.get("dev-prelogin-plan-selector"));
      } else if (
        window.location.host.indexOf("uat") > -1 ||
        window.location.host.indexOf("offline") > -1 ||
        window.location.host.indexOf("bluesteel") > -1
      ) {
        setSignInLink(mapContent.get("prod-offline-signin-link-plan-selector"));
        setCreateAccountLink(
          mapContent.get("prod-offline-create-account-link-plan-selector")
        );
        setPreLoginUrl(mapContent.get("prod-offline-prelogin-plan-selector"));
      } else {
        setSignInLink(mapContent.get("prod-online-signin-link-plan-selector"));
        setCreateAccountLink(
          mapContent.get("prod-online-create-account-link-plan-selector")
        );
        setPreLoginUrl(mapContent.get("prod-online-prelogin-plan-selector"));
      }
    }
  };

  const jumpToHSIDRegistration = () => {
    const clickEvent = {
      name: "internal-cta",
      data: { "Trigger UI Element Name": "registration" },
    };
    dtm.logClickEvent(clickEvent);
    window.location.href = createAccountLink;
    return;
  };

  const jumpToSignIn = (signinUrl, preSignInUrl) => {
    const clickEvent = {
      name: "internal-cta",
      data: { "Trigger UI Element Name": "sigin-in" },
    };
    dtm.logClickEvent(clickEvent);

    var redirectUrl = window.location.origin + ucardUrl;
    PreLoginApi(redirectUrl, preSignInUrl)
      .then(() => {
        jumpToPlanSelector(signinUrl);
        return;
      })
      .catch((error) => {
        jumpToPlanSelector(signinUrl);
        return;
      });
  };

  const jumpToPlanSelector = (signinUrl) => {
    window.location.href = signinUrl;
  };

  useEffect(() => {
    getSegmentContent();
    dtm.initializeAnalytics();
    const pageLoad = {
      pageName: "ucard activation ",
      pageTags: [],
      pageArgs: {},
    };
    const taxonomyObject = {
      division: "uhc",
      digitalAsset: "myuhc",
      siteSectionL1: "public",
      siteSectionL2: "",
    };
    dtm.setAddtionalAppData(taxonomyObject);
    dtm.logPageNavEvent(pageLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRegistrationRequest && signInLink && preLoginUrl) {
      jumpToSignIn(signInLink, preLoginUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInLink && preLoginUrl]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaInfo} />
      </Helmet>
      {isRegistrationRequest ? (
        <React.Fragment>
          <Spinner />
        </React.Fragment>
      ) : (
        <div>
          <Grid
            css={{
              "abyss-grid": {
                margin: "0",
              },
            }}
          >
            <Grid.Col
              css={{
                "abyss-grid-col": {
                  paddingBottom: "0",
                  "@media (min-width: 984px) and (max-width: 1247px)": {
                    paddingRight: "0",
                  },
                },
              }}
              span={{ xs: 12, md: 12, lg: 7, xl: 7 }}
            >
              <img
                className={styles.uhcLogo}
                alt="United HealthCare logo"
                src={UhcLogo}
              />
              <div className={styles.infoContent}>
                <Grid>
                  <Grid.Col span={{ xs: 12, md: 7, lg: 12, xl: 12 }}>
                    <div className={styles.title}>
                      {heading}
                      {/* Open doors to all your plan has to offer when you activate your UCard. */}
                    </div>
                    <div className={styles.description}>
                      {description}
                      {/* Activate your UnitedHealthcare UCard® today.  Sign in or create your UnitedHealthcare account to activate your UCard. */}
                    </div>
                  </Grid.Col>
                  <Grid.Col
                    css={{
                      "abyss-grid-col": {
                        display: "none",
                        "@media (min-width: 744px) and (max-width: 983px)": {
                          display: "flex",
                          justifyContent: "end",
                        },
                      },
                    }}
                    span={{ xs: 12, md: 5, lg: 5, xl: 5 }}
                  >
                    <div>
                      <img
                        className={styles.handHoldingCardTabletImg}
                        alt="Hand Holding Card Tablet Img"
                        src={HandHoldingCardTabletImage}
                      />
                    </div>
                  </Grid.Col>
                </Grid>
                <Grid
                  css={{
                    "abyss-grid": {
                      display: "flex",
                      alignItems: "center",
                      margin: "60px 0 20px",
                      padding: "10px 10px 3px",
                      backgroundColor: "#E5F8FB",
                      borderRadius: "8px",
                      boxShadow: "2px 4px 4px rgba(25, 25, 26, 0.16)",
                      "@media only screen and (max-width: 500px)": {
                        margin: "35px 0 30px",
                      },
                    },
                  }}
                >
                  <Grid.Col
                    span={{ xs: 12, md: 8, lg: 8, xl: 8 }}
                    css={{
                      "abyss-grid-col": {
                        "@media (min-width: 984px) and (max-width: 1247px)": {
                          padding: "12px 0px 12px 8px",
                        },
                      },
                    }}
                  >
                    <div className={styles.signInTitle}>
                      {signInHeading}
                      {/* Already Have a UnitedHealthcare account powered by HealthSafe ID? */}
                    </div>
                    <div className={styles.description}>
                      {signInDescription}
                      {/* Sign in to activate your UCard. */}
                    </div>
                  </Grid.Col>
                  <Grid.Col
                    span={{ xs: 12, md: 4, lg: 4, xl: 4 }}
                    css={{
                      "abyss-grid-col": {
                        display: "flex",
                        justifyContent: "center",
                        "@media only screen and (max-width: 743px)": {
                          justifyContent: "start",
                          paddingTop: "17px",
                        },
                      },
                    }}
                  >
                    <Button
                      css={{
                        "abyss-button-root": {
                          width: "158px",
                          padding: "0 5px",
                        },
                      }}
                      onClick={() => jumpToSignIn(signInLink, preLoginUrl)}
                      isDisabled={disableButtons}
                    >
                      <span className={styles.signIn}>
                        {signInButtonText}
                        {/* Sign in */}
                      </span>
                      <img alt="Arrow Icon" src={ArrowIcon} />
                    </Button>
                  </Grid.Col>
                </Grid>
                <Grid
                  css={{
                    "abyss-grid": {
                      display: "flex",
                      alignItems: "center",
                      margin: "0",
                      padding: "10px 10px 3px",
                      backgroundColor: "#F3F3F3",
                      borderRadius: "8px",
                      boxShadow: "2px 4px 4px rgba(25, 25, 26, 0.16)",
                    },
                  }}
                >
                  <Grid.Col
                    span={{ xs: 12, md: 8, lg: 8, xl: 8 }}
                    css={{
                      "abyss-grid-col": {
                        "@media (min-width: 984px) and (max-width: 1247px)": {
                          padding: "12px 0px 12px 8px",
                        },
                      },
                    }}
                  >
                    <div className={styles.registerTitle}>
                      {createAccountHeading}
                      {/* Create a new UnitedHealthcare account powered by HealthSafe ID */}
                    </div>
                    <div className={styles.description}>
                      {createAccountDescription}
                      {/* Create an account to activate your UCard. */}
                    </div>
                  </Grid.Col>
                  <Grid.Col
                    span={{ xs: 12, md: 4, lg: 4, xl: 4 }}
                    css={{
                      "abyss-grid-col": {
                        display: "flex",
                        justifyContent: "center",
                        "@media only screen and (max-width: 743px)": {
                          justifyContent: "start",
                          paddingTop: "18px",
                        },
                      },
                    }}
                  >
                    <Button
                      variant="outline"
                      css={{
                        "abyss-button-root": {
                          width: "158px",
                          padding: "0 5px",
                        },
                      }}
                      onClick={jumpToHSIDRegistration}
                      isDisabled={disableButtons}
                    >
                      <span className={styles.registerNow}>
                        {createAccountButtonText}
                        {/* Register now */}
                      </span>
                      <img alt="Plus Icon" src={PlusIcon} />
                    </Button>
                  </Grid.Col>
                </Grid>
              </div>
            </Grid.Col>
            <Grid.Col
              css={{
                "abyss-grid-col": {
                  backgroundColor: "#00bed5",
                  display: "flex",
                  justifyContent: "end",
                  padding: "0",
                  "@media (min-width: 984px) and (max-width: 1247px)": {
                    backgroundColor: "transparent",
                  },
                  "@media only screen and (max-width: 983px)": {
                    display: "none",
                  },
                },
              }}
              span={{ xs: 12, md: 5, lg: 5, xl: 5 }}
            >
              <div>
                <img
                  className={styles.handHoldingCardImg}
                  alt="Hand Holding Card Img"
                  src={HandHoldingCardImage}
                />
                <img
                  className={styles.handHoldingCardHalfImg}
                  alt="Hand Holding Card Img"
                  src={HandHoldingCardHalfImage}
                />
              </div>
            </Grid.Col>
          </Grid>
          <div className={styles.footer} />
        </div>
      )}
    </>
  );
};
